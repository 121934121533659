import { createApp } from 'vue';
import App from '@/app/App.vue';
import router from '@/app/router/appRouter';
import { createPinia } from 'pinia';
import { db } from '@/db/idb.js';
import { scannerPlugin } from './scanner/plugin/scannerPlugin';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/custom.scss';
import '@/app/asset/styles.css';

import DefaultLayout from '@/app/layout/DefaultLayout.vue';
import LoginLayout from '@/app/layout/LoginLayout.vue';

// Init Db
async () => {
  await db;
};

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(scannerPlugin);
app.component('DefaultLayout', DefaultLayout);
app.component('LoginLayout', LoginLayout);
app.mount('#app');
