<template>
  <OffcanvasComponent :type="'offcanvas-end'" ref="offcanvasRef">
    <template #header> Account </template>
    <template #body>
      {{ fullname }}<br />
      {{ email }}<br /><br />
      <button
        type="button"
        class="btn btn-danger"
        @click="logoutAndClearData()"
      >
        Logout
      </button>
    </template>
  </OffcanvasComponent>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useMsal } from '@/msal/composable/useMsal';
import { cacheService } from '@/cache/service';
import { keyval } from '@/db/keyval';
import OffcanvasComponent from '@/app/component/OffcanvasComponent.vue';

// Props
const props = defineProps({
  offcanvasOpen: {
    type: Boolean,
    required: true,
  },
});

// Events
const emit = defineEmits(['toggleOffcanvas']);

// Composables
const { logout } = useMsal();
const { email, fullname } = useMsal();

// Template properties
const offcanvasRef = ref(null);

const logoutAndClearData = async () => {
  // Clear customer object stores but NOT orders
  await cacheService.clear();
  await keyval.set('updatedDate', null);

  // Logout of MSAL
  logout();
};

watch(
  () => props.offcanvasOpen,
  (value) => {
    if (value) {
      offcanvasRef.value.open();
      emit('toggleOffcanvas');
    }
  }
);
</script>
