'use strict';

import { PublicClientApplication, LogLevel } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
const msalConfig = {
  auth: {
    clientId: '20bd67c7-ff5d-44cb-b5cc-5b6050c022e6',
    authority:
      'https://login.microsoftonline.com/8f8be490-5ae7-4908-9582-c30ac54729e4',
    redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

// Msal object
export const msalInstance = new PublicClientApplication(msalConfig);
