'use strict';

import { ref, reactive, watch } from 'vue';
import { useDocumentVisibility } from '@vueuse/core';
import {
  Capture,
  SktErrors,
  CaptureEventIds,
  CaptureProperty,
  CapturePropertyIds,
  CapturePropertyTypes,
  DataConfirmationBeep,
  DataConfirmationRumble,
} from 'socketmobile-capturejs';

/**
 * Plugin that sets the connects the scanner to the app and creates the $scanner global property
 */
export const scannerPlugin = {
  install: (app) => {
    // Reactive properties
    const status = ref(-1);
    const data = ref(null);
    const battery = ref('0%');

    // Local properties
    const appInfo = {
      appId: 'web:vmi.thruwayinc.com',
      developerId: '913be9c5-e0b6-ec11-983e-000d3a5a7cc9',
      appKey: 'MC4CFQCSkRT9JVvsd7JagbmoeKcL4DiVYQIVAOIOcwgFJEAzeS6P17J1Cz0yNHxP',
    };
    const capture = new Capture();
    let device = null;

    // Composables
    const visibility = useDocumentVisibility();

    /**
     * Event callback for after CaptureJS is opened
     * @param {Number} event - Id of the event
     * @returns {void}
     */
    const onCaptureEvent = async (event) => {
      if (!event) {
        return;
      }

      // Device arrival
      if (event.id === CaptureEventIds.DeviceArrival) {
        device = new Capture();
        const { guid } = event.value;

        try {
          const result = await device.openDevice(guid, capture);
          status.value = result;

          // Device is connected, check battery
          getBatteryLevel();
        } catch (e) {
          status.value = e;
        }
      }

      // Device removal
      if (event.id === CaptureEventIds.DeviceRemoval) {
        try {
          delete device.guid;
          await device.close();
          status.value = -1;
        } catch (e) {
          status.value = e;
        }
      }

      // Decode data
      if (event.id === CaptureEventIds.DecodedData) {
        data.value = String.fromCharCode.apply(null, event.value.data);
        //console.log('Scan', data.value);
      }
    };

    /**
     * Get battery level of scanner
     * @param {Object} device
     * @returns {void}
     */
    const getBatteryLevel = async () => {
      const property = new CaptureProperty(
        CapturePropertyIds.BatteryLevelDevice,
        CapturePropertyTypes.None,
        {}
      );

      try {
        const { value } = await device.getProperty(property);
        battery.value = `${(value & 0xff00) >> 8}%`;
      } catch (e) {
        console.log('Error while getting the battery level', e);
        battery.value = 0;
      }
    };

    const confirm = () => {
      const action =
        (DataConfirmationRumble.Good << 4) + (DataConfirmationBeep.Good << 2);
      const property = new CaptureProperty(
        CapturePropertyIds.DataConfirmationDevice,
        CapturePropertyTypes.Ulong,
        action
      );
      device.setProperty(property);
    };

    const warning = () => {
      const action =
        (DataConfirmationRumble.Bad << 4) + (DataConfirmationBeep.Bad << 2);
      const property = new CaptureProperty(
        CapturePropertyIds.DataConfirmationDevice,
        CapturePropertyTypes.Ulong,
        action
      );
      device.setProperty(property);
    };

    /**
     * Initialized CaptureJs service
     * @returns {void}
     */
    const init = async () => {
      try {
        await capture.open(appInfo, onCaptureEvent);
        //console.log('Opening Capture result', result);
      } catch (e) {
        const finalError = e.error || e;
        // error code to watch for to check if the Companion service is running
        if (finalError === SktErrors.ESKT_UNABLEOPENDEVICE) {
          console.log('Not able to connect to the service, is it running?');
        } else {
          console.log('Error connecting to service', finalError);
        }
      }

      // Check battery level every 1 min
      setInterval(() => {
        if (status.value === 0) getBatteryLevel();
      }, 1 * 60 * 1000);
    };

    // Initialize the scanner
    init();

    /**
     * When app returns from background state and scanner is connected, update battery
     */
    watch(
      () => visibility.value,
      (value) => {
        if (value === 'visible' && status.value === 0) getBatteryLevel();
      }
    )

    // Create object of refs
    const state = {
      warning,
      confirm,
      status,
      data,
      battery
    };

    app.provide('scanner', state)

    //app.config.globalProperties.$scanner = state;
  }
}

