<template>
  <footer id="footer">
    <div class="row fixed-bottom border-top bg-white">
      <div class="col">
        <RouterLink :to="{ name: 'Home' }" class="nav-link py-0">
          <button type="button" class="btn p-0 w-100">
            <i class="fas fa-home"></i>
            <div class="link-text">Home</div>
          </button>
        </RouterLink>
      </div>
      <div class="col">
        <RouterLink :to="{ name: 'CustomerList' }" class="nav-link py-0">
          <button type="button" class="btn p-0 w-100">
            <i class="fas fa-address-card"></i>
            <div class="link-text">Customers</div>
          </button>
        </RouterLink>
      </div>
      <div class="col">
        <RouterLink :to="{ name: 'OrderList' }" class="nav-link py-0">
          <button type="button" class="btn p-0 w-100">
            <i class="fas fa-shopping-cart"></i>
            <div class="link-text">Orders</div>
          </button>
        </RouterLink>
      </div>
    </div>
  </footer>
</template>

<script setup></script>

<style>
footer {
  text-align: center;
}
footer .navbar {
  margin: 0;
  padding: 0;
}
footer a {
  border-top: 3px solid transparent;
}
footer a button.btn {
  font-size: 1.25rem;
  color: #666;
}
footer .link-text {
  font-size: 0.75rem;
}

footer .router-link-active {
  color: #8dc73f;
  border-top: 3px solid #8dc73f;
}
footer .router-link-active button.btn {
  color: #8dc73f;
}
</style>
