'use strict';

import { useMsal } from '@/msal/composable/useMsal';

const { getToken } = useMsal();

const get = async () => {
  const headers = await getHeaders();

  return {
    method: 'GET',
    ...headers, // Merge current headers with auth header
  };
};

const post = async (body) => {
  const headers = await getHeaders();

  return {
    method: 'POST',
    ...headers, // Merge current headers with auth header
    body: JSON.stringify(body),
  };
};

const patch = async (body) => {
  const headers = await getHeaders();

  return {
    method: 'PATCH',
    ...headers, // Merge current headers with auth header
    body: JSON.stringify(body),
  };
};

const put = async (body) => {
  const headers = await getHeaders();

  return {
    method: 'PUT',
    ...headers, // Merge current headers with auth header
    body: JSON.stringify(body),
  };
};

const _delete = async () => {
  const headers = await getHeaders();

  return {
    method: 'DELETE',
    ...headers, // Merge current headers with auth header
  };
};

const getHeaders = async () => {
  const token = await getToken();
  const authHeader = token ? { Authorization: 'Bearer ' + token } : {};

  return {
    headers: {
      'Content-Type': 'application/json',
      ...authHeader, // Merge auth header object into header object
    },
  };
};

export const requestOptions = {
  get,
  post,
  put,
  patch,
  delete: _delete,
};

// export const requestOptions = {
//   get() {
//     const headers =
//     return {
//       method: 'GET',
//       ...headers(),
//     };
//   },
//   post(body) {
//     return {
//       method: 'POST',
//       ...headers(),
//       body: JSON.stringify(body),
//     };
//   },
//   patch(body) {
//     return {
//       method: 'PATCH',
//       ...headers(),
//       body: JSON.stringify(body),
//     };
//   },
//   put(body) {
//     return {
//       method: 'PUT',
//       ...headers(),
//       body: JSON.stringify(body),
//     };
//   },
//   delete() {
//     return {
//       method: 'DELETE',
//       ...headers(),
//     };
//   },
// };

// function headers() {
//   const token = authenticationService.getToken();
//   console.log(token);
//   const authHeader = token ? { Authorization: 'Bearer ' + token } : {};

//   return {
//     headers: {
//       'Content-Type': 'application/json',
//       ...authHeader
//     },
//   };
// }
